const Users = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85 6.95C7.85 5.2103 9.2603 3.8 11 3.8C12.7397 3.8 14.15 5.2103 14.15 6.95C14.15 8.6897 12.7397 10.1 11 10.1C9.2603 10.1 7.85 8.6897 7.85 6.95ZM11 2C8.26619 2 6.05 4.21619 6.05 6.95C6.05 9.68381 8.26619 11.9 11 11.9C13.7338 11.9 15.95 9.68381 15.95 6.95C15.95 4.21619 13.7338 2 11 2ZM7.4 13.7C4.41766 13.7 2 16.1177 2 19.1C2 19.5971 2.40294 20 2.9 20C3.39706 20 3.8 19.5971 3.8 19.1C3.8 17.1118 5.41177 15.5 7.4 15.5H14.6C16.5882 15.5 18.2 17.1118 18.2 19.1C18.2 19.5971 18.6029 20 19.1 20C19.5971 20 20 19.5971 20 19.1C20 16.1177 17.5823 13.7 14.6 13.7H7.4Z"
      />
    </svg>
  )
}
export default Users
